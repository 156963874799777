import { useEffect, useRef, useState } from "react";
import Service_Api, { ResponseProps } from "../../services/Api";
import { Invest } from "../../services/object/Invest";
import { GridCallbackDetails, GridRenderCellParams, GridRowParams, GridValueFormatterParams, MuiEvent } from "@mui/x-data-grid";
import { AlertColor, Box, Button, Chip, IconButton, Tab, Tabs } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import Notification, { NotificationProps } from "../../components/notification/Notification";
import Alert, { AlertProps } from "../../components/alert/Alert";
import TableChart from "../../components/tables/Tablechart";
import ModalFormComponent from "../../components/modal/ModalFormComponent";
import { User } from "../../services/object/User";
import { Category } from "../../services/object/Category";
import investPopupChildrens from './InvestPopupChildrens';
import { Salepoint } from "../../services/object/Salepoint";
import { displayFormErrors } from "../../services/tools/errorForm";
import { useLaw } from "../../services/auth/useLaw";
import ReplyIcon from '@mui/icons-material/Reply';
import TinyModalComponent from "../../components/tinyModal/TinyModalComponent";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import style from './assets/scss/accueil.module.scss';
import { useSearchParams } from "react-router-dom";
import { Univers } from "../../services/object/Univers";
import { jsDateToLocalFr, sqlToJsDate } from "../../services/tools/translateDate";
import { fakeObjectRadioBudget, fakeObjectRadioRoi } from "../../services/object/FakeObject";
import investTinyPopupChildrens from "./InvestTinyPopupChild";
import { Comment } from "../../services/object/Comment";
import { Law } from "../../services/object/Law";
import { useUser } from "../../services/auth/useUser";
import { JOB_DAF, JOB_DG, JOB_PDG, JOB_RAF } from "../../services/object/Job";

export const ADMIN = "6660860c8f1347.91483206";
export const AWAIT = 1;
export const AWAIT_VALID = 2;
export const VALID = 3;
export const REJECT = 4;
export const INFO = 5;

function Accueil() {
  const { law } = useLaw()
  const [dataRow, setDataRow] = useState<{invest :Invest, user: User, category: Category, salepoint: Salepoint[]}[]>();
  const [max, setMax] = useState<number>(0);
  const [invest, setInvest] = useState<Invest>();
  const [oldComment, setOldComment] = useState<string>("");
  const [newComment, setNewComment] = useState<string>("")
  const [TOComment, setTOComment] = useState<Comment[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [displayAlert, setDisplayAlert] = useState<AlertProps>();
  const [displayNotif, setDisplayNotif] = useState<NotificationProps>();
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
  const [popupChildren, setPopupChildren] = useState<JSX.Element[]>();
  const [TFile, setTFile] = useState<FileList>();
  const [TFilePath, setTFilePath] = useState<string[]>([]);
  const [categoryUid, setCategoryUid] = useState<string>("");
  const [TOCategory, setTOCategory] = useState<Category[]>([]);
  const [universUid, setUniversUid] = useState<string>("");
  const [TOUnivers, setTOUnivers] = useState<Univers[]>([]);
  const [salepointLabel, setSalepointLabel] = useState<string[]>([]);
  const [TOSalepoint, setTOSalepoint] = useState<Salepoint[]>([]);
  const [popupTitle, setPopupTitle] = useState<string>("Créer une nouvelle demande");
  const [admin, setAdmin] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [tinyModalOpen, setTinyModalOpen] = useState(false);
  const [tinyPopupChild, setTinyPopupChil] = useState<JSX.Element>();
  
  const [loading, setLoading] = useState(false);

  const formRefInvest = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const formRefState = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  
  const { user } = useUser()
  const Api = Service_Api();
  
  /* hydrate data onload */
  useEffect(() => {
      let url = new URLSearchParams(window.location.search);
      fecthData()
      setAdmin(law.some((law:Law) => { return law.uid === ADMIN}))
      let investUid = url.get('investUid') 
      if(investUid !== null){
        getInvest(investUid);
      }
  }, []);


  const getRowData = async (paginationModel: { page: number; pageSize: number;}, sort: {}, filter: {}, newState? : number) => {
    // fetch data from server
    setLoading(true)
    const response = await Api.post("invest/getAllInvestForDataGrid", {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sort,
        filter,
        state : ((newState === undefined) ? state : newState)
      });
    if(response?.success){
        setDataRow(response?.data);
        setMax(response?.recordsTotal ?? 0);
        setLoading(false)
    }else{
        setDataRow([]);
        setMax(0);
        setLoading(false)
    }
  };
  const fecthData = async() => {
    //get all invest (for row data)
    let response = await Api.get("category/");
    if(response?.success)
        setTOCategory(response.data);
    response = await Api.get("salepoint/getSalepointByUserUid");
    if(response?.success){
        setTOSalepoint(response.data);
    }
    response = await Api.get("univers/getUniversByUserUid");
    if(response?.success)
        setTOUnivers(response.data);
    getTotalsByState();
  }
    /* end hydrate data */
    /* table */
    //Create column
    const columns = [
        { field: "JRA_invest.id", headerName: 'Numéro', flex:1},
        { field: "JRA_invest.createdAt", headerName: 'Date de création', flex:1,
            valueFormatter: (params: GridValueFormatterParams<any>) => { 
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value));
                }else{
                    return params.value;
                }
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value));
                }else{
                    return params.value;
                }
                
            }
        },
        { field: "JRA_invest.modifiedAt", headerName: 'Date de dernière modification', flex:1,
            valueFormatter: (params: GridValueFormatterParams<any>) => { 
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value));
                }else{
                    return params.value;
                }
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value));
                }else{
                    return params.value;
                }
            }
        },
        { field: "JRA_user.firstname", headerName: 'Demandeur', flex:1},
        { field: "JRA_invest.name", headerName: 'Sujet', flex:1},
        { field: "JRA_invest.budget", headerName: 'Prévu au budget', flex:1,
            valueFormatter: (params: GridValueFormatterParams<any>) => { 
                if(params.value === 1){
                    return "Oui";
                }else{
                    return "Non";
                }
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                if(params.value === 1){
                    return "Oui";
                }else{
                    return "Non";
                }
            }},
        { field: "JRA_invest.budgetType", headerName: 'Type de Budget', flex:1,
            valueFormatter: (params: GridValueFormatterParams<any>) => { 
                if(params.value){
                    return fakeObjectRadioBudget.find((radioBudjet : {uid: number;name: string;} ) => (radioBudjet.uid === params.value))?.name
                }else{
                    return params.value;
                }
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                if(params.value){
                    let value = fakeObjectRadioBudget.find((radioBudjet : {uid: number;name: string;} ) => (radioBudjet.uid === params.value))?.name;
                    return <Box sx={{overflow: "hidden",
                                    textOverflow: "ellipsis"}} title={value}>
                                {value}
                            </Box>
                }else{
                    return params.value;
                }
            }},
        { field: "JRA_invest.roi", headerName: 'ROI', flex:1,
            valueFormatter: (params: GridValueFormatterParams<any>) => { 
                if(params.value){
                    return fakeObjectRadioRoi.find((radioRoi : {uid: number;name: string;} ) => (radioRoi.uid === params.value))?.name
                }else{
                    return params.value;
                }
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                if(params.value){
                    return fakeObjectRadioRoi.find((radioRoi : {uid: number;name: string;} ) => (radioRoi.uid === params.value))?.name
                }else{
                    return params.value;
                }
            }},
        { field: "JRA_invest_salepoint.salepointUid", headerName: 'Concession(s)', flex:1},
        { field: "JRA_invest.price", headerName: 'Montant', flex:1},
        { field: "JRA_category.label", headerName: 'Catégorie', flex:1},
        { field: "JRA_invest.state", headerName: 'Statut', flex:1, type: 'string',
                valueFormatter: (params: GridValueFormatterParams<any>) => params.value,
                renderCell: (params: GridRenderCellParams<any>) => {
                    switch (params.row['JRA_invest.state']) {
                        case 'En attente':
                            return (<Chip label={params.row['JRA_invest.state']} color="warning" variant="outlined"/>)
                        case 'Refusée':
                            return (<Chip label={params.row['JRA_invest.state']} color="error" variant="outlined"/>)
                        case 'Validée':
                            return (<Chip label={params.row['JRA_invest.state']} color="success" variant="outlined"/>)
                        case 'Informations':
                            return (<Chip label={params.row['JRA_invest.state']} color="info" variant="outlined"/>)
                        case 'En validation':
                            return (<Chip label={params.row['JRA_invest.state']} color="secondary" variant="outlined"/>)
                        default:
                            return (<Chip label={params.row['JRA_invest.state']} color="success" variant="outlined"/>)
                    }
                },
            },
        { field: "JRA_invest.validPDG", headerName: 'Validé par (Direction)', flex:1},
        { field: "JRA_invest.validDaf", headerName: 'Validé par (Finance)', flex:1},
        { field: 'action', headerName: 'Action', flex:1, type: 'boolean', filterable: false, sortable: false, disableExport: true, 
            renderCell: (params: GridRenderCellParams<any>) => (
            <>
                {
                        (
                            (((params.row['JRA_invest.state'] === "En attente" || params.row['JRA_invest.state'] === "Informations" || params.row['JRA_invest.state'] === "En validation")) ? 
                                <>
                                    {
                                        (params.row['JRA_user.firstname'] === user.firstname + " " + user.lastname) ?
                                            <IconButton title={"Modifier"}>
                                                <EditIcon/>
                                            </IconButton>
                                        :
                                            <></>
                                    }
                                    {
                                        (params.row['JRA_invest.state'] === "En attente"
                                        && params.row['JRA_user.firstname'] === user.firstname + " " + user.lastname) ?
                                            <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        : ""
                                    }
                                    {
                                        (admin
                                        && params.row['JRA_user.firstname'] !== user.firstname + " " + user.lastname) ?
                                        <IconButton title={"Statuer"}  onClick={(e) => {onStateClick(e, params)}}>
                                            <ReplyIcon/>
                                        </IconButton>
                                            :
                                        <></>
                                    }
                                </>
                            :
                                <>
                                    <IconButton title={"Voir"}>
                                        <Visibility/>
                                    </IconButton>
                                </>
                            )
                        )
                }
            </>),
        },
    ];

  const [value, setValue] = useState(0);
  const [state, setState] = useState<number>();
  const [total, setTotal] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    let id = (event.target as HTMLInputElement).id
    setValue(newValue);
    setState(parseInt(id));
    
    getRowData({ page: 0, pageSize: 20}, {}, {}, parseInt(id));
  };
  
  const [totalData, setTotalData] = useState([]);
  const getTotalsByState = async() => {
    const response = await Api.get("invest/getTotalInvestByState");
    let total = 0
    response?.data.forEach((item:any) => {
        total += parseInt(item.nb);
    });
    setTotal(total);
    setTotalData(response?.data);
  }

  //reset children data when invest change
  useEffect(() => {
      let children = investPopupChildrens({errorMessages, 
                                           formRefInvest, 
                                           invest, 
                                           categoryUid,
                                           salepointLabel,
                                           setSalepointLabel,
                                           TOCategory,
                                           TOSalepoint,
                                           handleFormInvestChange,
                                           TFilePath, 
                                           admin,
                                           disabled,
                                           TOUnivers,
                                           TOComment,
                                           universUid,
                                           newComment, 
                                           setNewComment});
      setPopupChildren(children)
  }, [invest, errorMessages, categoryUid, salepointLabel, TOCategory, TOSalepoint, TFilePath, admin, disabled, TOUnivers, universUid, TOComment, newComment]);
  
    //event click on row
    const onRowClick = (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
        let investUid = params.id as string
        getInvest(investUid)
        if( (admin && 
            params.row['JRA_user.firstname'] !== user.firstname + " " + user.lastname)
            || (params.row['JRA_invest.state'] !== "En attente" && params.row['JRA_invest.state'] !== "Informations")){
            setPopupTitle("La demande")
        }else{
            setPopupTitle("Modifier une demande")
        }
    }
    //event click on card
    const onCardClick = (investUid:string) => {
        getInvest(investUid)
    }
    //event click on row
    const onStateClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams) => {
        event.preventDefault();
        event.stopPropagation();
        let investUid = params.id as string
        getInvest(investUid)
        setPopupTitle("La demande")
    }
  //event click on icon delete
  const deleteLine = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams) =>{
    //desable onRowClick event
    event.preventDefault();
    event.stopPropagation();
    setDisplayAlert({
        open : true,
        title : "Suppression",
        contentText : "Êtes-vous sûr de vouloir supprimer cette demande ?",
        contentButtonSuccess : "Oui",
        contentButtonAbord : "Non",
        onSubmitSuccess:  async () => {
            //get current row id (= current invest id)
            let investUid = params.id as string
            let response = await Api.del("invest/", {uid : investUid});
            //reload table data
            let newData = dataRow?.filter(data => data.invest.uid !== investUid)
            setDataRow(newData)
            if(response?.success){
                setDisplayNotif({
                    open : true,
                    contentText : "La demande a été supprimée",
                    severity : "success",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }else{
                setDisplayNotif({
                    open : true,
                    contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                    severity : "error",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }
            setDisplayAlert(undefined)
        },
        onSubmitAbord:  () => (setDisplayAlert(undefined))
    })
    
}

  const getInvest = async(investUid:string) => {
        //get invest by uid
        let response = await Api.get("invest/", "uid="+investUid);
        //set current invest (to hydrate popup's input)
        setInvest(response?.data.invest);
        
        setOldComment(response?.data.invest.comment)
        if(response?.data.invest.state !== "En attente" && response?.data.invest.state !== "Informations"){
            setDisabled(true);
        }else{
            setDisabled(false);
        }
        setModalOpen(true);
        
        setCategoryUid(response?.data.category.uid)
        setUniversUid(response?.data.univers.uid)
        if(response?.data.salepoint){
        setSalepointLabel(response?.data.salepoint?.map((salepoint:Salepoint)=>{
            return salepoint.name;
        }))
        
        }
        response = await Api.get("file/getFileByInvestUid", "?investUid="+investUid);
        if(response?.success)
            setTFilePath(response.data.map((file:any) => (file.path)));

        response = await Api.get("comment/getAllCommentByInvestUid", "?investUid="+investUid);
        console.log("la")
        if(response?.success)
            setTOComment(response.data);
    
    }
  
    //event on click button create
    const handleOpenModal = () => {
        setModalOpen(true);
        setInvest((investValue : any) => ({
            ...investValue,
            ['userUid']: user.uid,
        }));
        setDisplayAlert(undefined);
        //reset input's data
    };

    const [searchParams, setSearchParams] = useSearchParams();
    
    //event on click icon close
    const handleCloseModal = () => {
        setModalOpen(false);
        setDisabled(false);
        //reset input's data
        resetPopup();
        if (searchParams.has('investUid')) {
            searchParams.delete('investUid');
            setSearchParams(searchParams);
          }
    };

    const resetPopup = () => {
        setInvest(undefined)
        setCategoryUid("")
        setUniversUid('')
        setSalepointLabel([])
        setTOComment([])
        setErrorMessages({})
        setPopupTitle("Créer une demande")
        setTFile(undefined);
        setNewComment("")
        setOldComment("")
        setTFilePath([]);
    }
    const handleSumbitTinyPopup = async (state :number) => {

        let data = {
            investUid : invest?.uid,
            state : state,
            commentState :comment
        }
        if(state !== VALID){
            let error = displayFormErrors(formRefState);
            if(Object.keys(error).length !== 0){
                setErrorMessages(error);
                return false;
            }
        }
        let prevState = invest?.state
        let response = await Api.post("invest/updateState", data);
        if(response?.success){
            if(comment !== invest?.commentState){
                if(invest?.state !== "Validée")
                    await Api.post("comment/", {
                        comment : comment,
                        investUid : invest?.uid
                    });
            }

            let oldData = dataRow
            if(oldData){
                const index = oldData.findIndex(data => data.invest.uid === response?.data.invest.uid);
                // oldData = oldData.filter(data => data.invest.uid !== invest.uid)
                if(state !== value && value !== 0 )
                    oldData = oldData.filter(data => data.invest.uid !== response?.data.invest.uid)
                else
                    oldData[index].invest = response?.data.invest;
                let oldTotalData = totalData
                if(prevState){
                    oldTotalData.map((item :any)=> {
                        item.nb = (prevState?.toUpperCase() === item.statestring) ? 
                            item.nb-1 
                        : 
                            item.nb;
                        return (item)
                    })
                    oldTotalData.map((item :any)=> {
                        item.nb = (response?.data.invest.state.toUpperCase() === item.statestring) ?
                          item.nb+1 
                         :
                          item.nb
                        return (item)
                      })
                }
                setTotalData(oldTotalData);
                
                setDataRow(oldData);
            }
            let content = {
                open : true,
                contentText : "Action effectuée, la demande passera en validée lorsque la deuxième validation sera effectuée",
                severity : "success" as AlertColor,
                handleClose:  ()=>(setDisplayNotif(undefined))
            }
            if(state === VALID){
                if(response?.data.invest.validDaf && response?.data.invest.validPDG){
                    content.contentText = "Demande validée";
                }else{
                    content.contentText = "Action effectuée, la demande passera en validée lorsque la deuxième validation sera effectuée";
                }
            }else if(state === REJECT){
                content.contentText = "Demande refusée";
            }else{
                content.contentText = "Demande d'information envoyée";
            }
           
            setDisplayNotif(content)  
            handleCloseModal();
            handleCloseTinyModal();
        }
        else{
            setDisplayNotif({
                open : true,
                contentText : "Une erreur inconnu est survenue",
                severity : "error",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })  
            handleCloseModal();
            handleCloseTinyModal();
            return false;
        }
        return true;
    }

    const [info, setInfo] = useState(false);
    const [reject, setReject] = useState(false);
    const [comment, setComment] = useState("");
    //reset children data when invest change
    useEffect(() => {
        let child = investTinyPopupChildrens({  handleButton: handleSumbitTinyPopup,
                                            info,
                                            setInfo,
                                            reject,
                                            setReject,
                                            comment: (comment) ? comment : (invest?.commentState ?? ""),
                                            setComment,
                                            formRefState,
                                            errorMessages,
                                            invest,
                                            user });
        setTinyPopupChil(child)
    }, [info, reject, comment, tinyModalOpen, errorMessages]);
    //event on click icon close
    const handleCloseTinyModal = () => {
        setInfo(false);
        setReject(false);
        setComment("");
        setTinyModalOpen(false);
        //reset input's data
    };

    const onFormSubmitSuccess = async () => {
        if(!admin || invest?.userUid === user.uid){
            if(invest?.state === "En attente" || invest?.state === "Informations" || typeof invest?.state === 'undefined'){
                let error = displayFormErrors(formRefInvest);
                if(Object.keys(error).length === 0){
                    //if current invest has uid it exist so put
                    let response : ResponseProps|undefined;
                    let investUid = invest?.uid;
                    let TsalepointUidPost = (TOSalepoint.filter((salepoint : Salepoint) => { return salepointLabel.indexOf(salepoint.name) > -1})).map((salepoint :Salepoint) => {return salepoint.uid})
                    let TsalepointUidDelete = (TOSalepoint.filter((salepoint : Salepoint) => { return TsalepointUidPost.indexOf(salepoint.uid) === -1})).map((salepoint :Salepoint) => {return salepoint.uid})

                    if(invest?.uid){
                        let newInvest = invest;
                        newInvest.state = "En attente";
                        response = await Api.put("invest/", newInvest);
                        let oldData = dataRow;
                        if(oldData){
                            const index = oldData.findIndex(data => data.invest.uid === invest.uid);
                            // oldData = oldData.filter(data => data.invest.uid !== invest.uid)
                            oldData[index].invest = invest;
                            setDataRow(oldData);
                        }                
                        let oldTotalData = totalData
                        if(invest?.state){
                            oldTotalData.map((item :any)=> {
                                item.nb = (invest?.state?.toUpperCase() === item.statestring) ? 
                                    item.nb-1 
                                : 
                                    item.nb;
                                return (item)
                            })
                            oldTotalData.map((item :any)=> {
                                item.nb = (response?.data.invest.state.toUpperCase() === item.statestring) ?
                                item.nb+1 
                                :
                                item.nb
                                return (item)
                            })
                        }
                        setTotalData(oldTotalData);
                    //else create it
                    }else{
                        response = await Api.post("invest/", {invest : invest, TsalepointUid: TsalepointUidPost});
                        //reload table data
                        let newData = dataRow;
                        newData?.unshift(response?.data)
                        setDataRow(newData);
                        //set new current invest
                        setInvest(response?.data.invest)
                        investUid = response?.data.invest.uid;
                        
                        let oldTotalData = totalData
                        oldTotalData.map((item :any)=> {
                            item.nb = ("EN ATTENTE" === item.statestring) ? 
                                item.nb+1
                            : 
                                item.nb;
                            return (item)
                        })
                    }
                    if(invest?.comment !== oldComment){
                        await Api.post("comment/", {
                            comment : invest?.comment,
                            investUid : investUid
                        });
                    }
                    Api.post("salepoint/changeAssoAllUid", {Tuid: TsalepointUidPost, investUid: investUid});
                    Api.del("salepoint/changeAssoAllUid", {Tuid: TsalepointUidDelete, investUid: investUid});

                    if(TFile && TFile.length){
                        const formdata = new FormData();
                        for (let i = 0; i < TFile.length; ++i) {
                            formdata.append("file", TFile[i] as unknown as Blob);
                            formdata.append("investUid", investUid ?? "");
                            Api.post('file/', formdata, {file:true})
                        }
                    }
                    
                    let oldData = dataRow;
                    if(oldData){
                        const index = oldData.findIndex(data => data.invest.uid === investUid);
                        // oldData = oldData.filter(data => data.invest.uid !== investUid)
                        oldData[index].salepoint = TOSalepoint.filter((salepoint : Salepoint) => { return salepointLabel.indexOf(salepoint.name) > -1});
                        setDataRow(oldData);
                    }               
                    
                    handleCloseModal();
                    if(response?.success)
                        setDisplayNotif({
                            open : true,
                            contentText : "Demande enregistrée",
                            severity : "success",
                            handleClose:  ()=>(setDisplayNotif(undefined))
                        })  
                    else{
                        setDisplayNotif({
                            open : true,
                            contentText : "Une erreur inconnu est survenue",
                            severity : "error",
                            handleClose:  ()=>(setDisplayNotif(undefined))
                        })  
                        return false;
                    }
                    return true;
                }else{
                    setErrorMessages(error);
                    return false;
                }
            }else{
                handleCloseModal();
                return true;
            } 
        }else{
            if(invest?.state === "En attente" || invest?.state === "En validation" || invest?.state === "Informations")
                setTinyModalOpen(true)
            else
                handleCloseModal();
            return true;
        }
    }

    const handleFormInvestChange = (property: string, value: string| FileList | number | boolean | null) => {
        if(property === "categoryUid" && typeof value === "string"){
            setCategoryUid(value)
        }
        if(property === "universUid" && typeof value === "string"){
            setUniversUid(value)
            setSalepointLabel(TOSalepoint.filter((salepoint : Salepoint) => ( salepoint.universUid === value )).map((salepoint :Salepoint) => {return salepoint.name}))
        }
        if(typeof value !== "number" && typeof value !== "boolean" && typeof value !== "string" &&  property === "file" ){
            if(value?.length && value[0] instanceof Blob){
                for (let i = 0; i < value.length; ++i) {
                    const file = value[i];
                    setTFilePath((path) => [...path, URL.createObjectURL(file)]);

                }
                setTFile(value)
            }else{
                setTFile(undefined)
                setTFilePath([]);
            }
        }else{
            if(typeof value === "string" && property === "price" && value){
                value = value.replace(/ /g,'')
                value = value.replace(/€/g,'')
            }
            setInvest((investValue : any) => ({
                ...investValue,
                [property]: value,
            }));
        }
    }
  return (
    <div className="background">
      <div className="backsquare">
      <h1 className='title2'>Demande d'investissement</h1>
        <p>
            Avez-vous pensé à regarder sur <a href="https://re-use.jra.tools/" target="_blank" rel="noopener noreferrer"> ReYouz </a> ? une Concession du Groupe souhaite peut-être céder ce que vous cherchez !
        </p>
        <p>
            Dans le cas contraire, vous pouvez faire votre demande d'investissement via cet interface JRA Invest'
        </p>
        <div className="tableContainer">
            <Button  className="addbutton" 
                     variant="contained" 
                     sx={{width:260, height:50}} 
                     onClick={handleOpenModal} 
                     endIcon={<AddIcon/>}> 
                Créer une demande
            </Button>
            
            <Tabs className={style.tabsContainer} 
                    value={value} 
                    onChange={handleTabChange} 
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { display: 'none' } }}>
                <Tab className={(state === undefined || isNaN(parseInt(state as unknown as string))) ? style.selected : ""} label={"GLOBAL ("+ total+ ")"}/>
                {
                    totalData.map((item:any, key:number) => {
                        return <Tab className={(key+1 === state) ? style.selected : ""} key={key} id={item.state} label={item.statestring + " ("+ item.nb+ ")"}/>
                    })
                }
            </Tabs>
                <TableChart 
                    //onTableChange event do this func
                    callBackFunctionGetRowData={getRowData} 
                    //send new data to table and map to match structure of datagrid row
                    dataRow={dataRow?.map(
                        (data:{invest :Invest, user: User, category: Category, salepoint: Salepoint[]}) => {
                            return  { 
                                "id" : data.invest.uid,
                                "JRA_invest.id" : data.invest.id,
                                "JRA_invest.createdAt" : data.invest.createdAt,
                                "JRA_invest.modifiedAt" : data.invest.modifiedAt,
                                "JRA_invest.price" : String(data.invest.price).replaceAll('.', ',') +" €",
                                "JRA_invest.name" : data.invest.name,
                                "JRA_invest.budget" : data.invest.budget,
                                "JRA_invest.budgetType" : data.invest.budgetType,
                                "JRA_invest.roi" : data.invest.roi,
                                "JRA_invest_salepoint.salepointUid" :  data.salepoint?.map((salepoint:Salepoint)=>{
                                                    return salepoint.name;
                                                }) as unknown as string,
                                "JRA_user.firstname" : data.user.firstname + " " + data.user.lastname,
                                "JRA_category.label" : data.category.label,
                                "JRA_invest.state" : data.invest.state,
                                "JRA_invest.validPDG" : data.invest.validPDG,
                                "JRA_invest.validDaf" : data.invest.validDaf,
                            }
                        }
                    )}
                    columns={columns} 
                    columnVisibility = {{
                        "JRA_invest.createdAt": false,
                        "JRA_invest.modifiedAt" : false,
                        "JRA_invest.budget" : false,
                        "JRA_invest.budgetType" : false,
                        "JRA_invest.roi" : false
                      }}
                    onRowClick={onRowClick}
                    onCardClick={onCardClick}
                    handleDeleteRow={(e)=>(true)}
                    loading={loading}
                    max={max}
                    />
            <ModalFormComponent
                title={popupTitle}
                modalOpen={modalOpen} 
                onClose={handleCloseModal} 
                onFormSubmitSuccess={onFormSubmitSuccess} 
                childrenForm={popupChildren}
                formEvent={[onFormSubmitSuccess]}
                buttonText={(
                    (admin && invest?.userUid !== user.uid) 
                    && typeof invest !== "undefined"
                    && (invest?.state === "En attente" || invest?.state === "Informations" || invest?.state === "En validation")) 
                    ? "Statuer" 
                    : (invest?.state !== "En attente"
                       && (invest?.userUid !== user.uid)) 
                        ? "Fermer"
                        : null
                } 
                buttonIcon={(admin) ? <KeyboardDoubleArrowRightIcon/> : null}/>

            <TinyModalComponent 
                    title={"Statuer sur la demande"}
                    modalOpen={tinyModalOpen} 
                    onClose={handleCloseTinyModal} 
                    child={tinyPopupChild}
                    progess={false}/>
            {
                (displayAlert?.open === true) ? <Alert {...displayAlert}/>: ""
            }
            {
                (displayNotif?.open === true) ? <Notification {...displayNotif}/>: ""
            }
        </div>
      </div>
    </div>
  );
};

export default Accueil;